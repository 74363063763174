import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { ValidationService, Validators as V } from "../../shared/services/validation.service";
import { catchError, throwError } from "rxjs";
import { NotificationService } from "../../shared/services/notification.service";
import { NetworksSelectComponent } from "../../shared/components/networks-select/networks-select.component";
import { CoverageUpdate } from "../../shared/models/coverage.model";
import {
    ProductSelect,
    ProductsSelectComponent
} from "../../shared/components/products-select/products-select.component";
import { CoverageService } from "../../shared/services/coverage.service";

@Component({
  selector: 'app-coverage-form',
  templateUrl: './coverage-form.component.html',
  styleUrls: ['./coverage-form.component.scss']
})
export class CoverageFormComponent implements OnInit {

    @Input() model: CoverageUpdate;
    @Output() onAfterSave = new EventEmitter<CoverageUpdate>();
    @Output() onCancel = new EventEmitter<void>();

    form: FormGroup;
    loading = false;
    disableNetworks = false;

    @ViewChild(NetworksSelectComponent, { static: false }) networksSelectComponent: NetworksSelectComponent;
    @ViewChild(ProductsSelectComponent, { static: false }) productsSelectComponent: ProductsSelectComponent;

    constructor(
        private formBuilder: FormBuilder,
        public validationService: ValidationService,
        private coverageService: CoverageService,
        private notifications: NotificationService
    ) {

    }

    ngOnInit(): void {
        this.form = this.formBuilder.group({
            networkId: [this.model.networkId, V.compose([V.required])],
            productId: [this.model.productId, V.compose([V.required])],
            price: [this.model.price, V.compose([V.required, V.price(4, true), V.min(0, true)])],
            enabled: [this.model.enabled]
        });
        if (this.model.id) {
            this.disableNetworks = true;
        }
    }

    onSelectNetworks(networkIds: number[]) {
        this.model.networkId = networkIds.length ? networkIds[0] : null;
        this.form.controls.networkId.patchValue(networkIds.length ? networkIds[0] : null);
    }

    onSelectProducts(products: ProductSelect[]) {
        const val = products.length ? products[0].product.id : null;
        this.model.productId = val;
        this.form.controls.productId.patchValue(val);
    }

    onSave() {
        this.loading = true;
        const model = Object.assign(this.model, this.form.value);
        this.coverageService.save(model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(r=> {
            this.loading = false;
            this.notifications.success('Coverage ' + (this.model.id ? 'updated' : 'created'), 'Coverage');
            this.onAfterSave.emit(r);
        });
    }

}
