import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { Session, SessionAllRequestParams } from "../models/session.model";
import { ApiResponse, Page } from "../models/utils";
import { Sms, SmsSearch } from "../models/sms.model";

@Injectable({
    providedIn: 'root'
})
export class SmsService {

    static readonly deliveredStatuses = [
        {id: 'SCHEDULED', name: 'Scheduled'},
        {id: 'ENROUTE', name: 'Enroute'},
        {id: 'DELIVERED', name: 'Delivered'},
        {id: 'EXPIRED', name: 'Expired'},
        {id: 'DELETED', name: 'Deleted'},
        {id: 'UNDELIVERABLE', name: 'Undeliverable'},
        {id: 'ACCEPTED', name: 'Accepted'},
        {id: 'UNKNOWN', name: 'Unknown'},
        {id: 'REJECTED', name: 'Rejected'},
        {id: 'SKIPPED', name: 'Skipped'},
        {id: 'PENDING', name: 'Pending'},
        {id: 'ROUTED', name: 'Routed'},
        {id: 'SUBMITTED', name: 'Submitted'},
        {id: 'SUBMIT_SM_FAILED', name: 'SUBMIT_SM failed'},
        {id: 'INTERNAL_ERROR', name: 'Internal error'},
        {id: 'NO_DLR_RECEIVED', name: 'No DLR received'},
        {id: 'PARTIALLY_DELIVERED', name: 'Partially delivered'},
    ];

    constructor( private http: HttpClient) {}

    all(size: number = 20, lastCreatedAt: string = null, search: SmsSearch = null): Observable<Sms[]> {
        const query = {
            size: size,
            lastCreatedAt: lastCreatedAt,
        };
        if (!query.lastCreatedAt) {
            delete query.lastCreatedAt;
        }
        const url = `%BASE_URL%/state-manager/%ROLE%/messages/search`;
        return this.http.post<ApiResponse<Sms[]>>(url, search,{withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

}
