import { Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    CellClickedEvent,
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, Subscription, throwError } from "rxjs";
import { CoverageTableActionsRendererComponent } from "./coverage-table-actions-renderer.component";
import { debounceTime } from "rxjs/operators";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { CurrencyPipe } from "@angular/common";
import {
    CoverageAllRequestParams,
    CoverageSearch, CoverageUpdate,
    CoverageView,
    coverageViewToUpdate
} from "../../shared/models/coverage.model";
import { CoverageService } from "../../shared/services/coverage.service";

@Component({
    selector: 'app-coverage-table',
    templateUrl: './coverage-table.component.html',
    styleUrls: [],
})
export class CoverageTableComponent extends AGTableBase implements OnInit, OnDestroy {
    theme = AgGridUtils.theme;
    gridApi: GridApi<CoverageView>;

    gridOptions: GridOptions<CoverageView> = {
        onGridReady: e => {
            const userId = this.userService.user.value.id;
            this.gridApi = e.api;
            const columnState = this.storage.getItem<ColumnState[]>(`coverage-table-columns-${userId}`) || [];
            if (columnState && columnState.length) {
                this.gridApi.applyColumnState({ state: columnState });
            }
            //this.addHorizontalScrollOnTop();
            this.update();
        },
        onSortChanged: (e: SortChangedEvent) => this.columnChange$.next(e),
        onColumnResized: (event: ColumnResizedEvent<CoverageView>) => this.onColumnResized(event),
        onColumnMoved: (event: ColumnMovedEvent<CoverageView>) => this.onColumnMoved(event),
        onColumnVisible: (event: ColumnVisibleEvent<CoverageView>) => this.onColumnVisible(event),
        getMainMenuItems: (params: GetMainMenuItemsParams) => this.getMainMenuItems(params),
        icons: this.customIcons,
        defaultColDef: this.defaultColDef,
        domLayout: this.domLayout,
        columnDefs: [
            {
                headerName: 'Country',
                field: 'network.countryName',
                sortable: false,
            },
            {
                headerName: 'Network',
                field: 'network.operatorName',
                sortable: false,
                cellRenderer: (params: ICellRendererParams<CoverageView>) => {
                    const s = params.data.network;
                    return `<div class="one-line d-flex align-items-center gap-0_5">${s.operatorName}(${s.mcc}/${s.mnc})</div>`;
                },
            },
            {
                headerName: 'Enabled',
                field: 'enabled',
                maxWidth: 200,
                minWidth: 200,
                //sortable: true,
                //comparator: () => 0,
                cellRenderer: (params: ICellRendererParams<CoverageView>) => {
                    const isChecked = params.data.enabled ? 'checked' : '';
                    return `<div class="form-check form-switch">
                            <input type="checkbox" class="form-check-input medium" ${isChecked}/>
                        </div>`;
                },
                onCellClicked: (event: CellClickedEvent<CoverageView>) => this.onCellClickEnabled(event),
            },
            {
                headerName: 'Route',
                field: 'productName',
                sortable: false,
                minWidth: 250,
                cellRenderer: (params: ICellRendererParams<CoverageView>) => {
                    const d = params.data;
                    return `<div class="one-line d-flex align-items-center gap-0_5">${d.supplierName} (${d.productName})</div>`;
                },
            },
            {
                headerName: 'Price',
                field: 'price',
                sortable: false,
                cellRenderer: (params: ICellRendererParams<CoverageView>) => {
                    const d = params.data;
                    return `<div class="one-line d-flex align-items-center gap-0_5">${this.currencyPipe.transform(d.price, d.currency, true, '1.0-4')}</div>`;
                },
            },
            {
                headerName: 'Actions',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: false,
                suppressMenu: false,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: CoverageTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: CoverageTableAction, params: ICellRendererParams<CoverageView>) => this.onAction(action, params)
                },
                getQuickFilterText: params => '',
            }
        ],
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
    };

    rows: CoverageView[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('coverageModalTpl', { read: TemplateRef, static: false }) coverageModalTpl: any;
    coverageModal: DialogRef;
    editModel: CoverageUpdate;

    @Input() filter: CoverageSearch;

    constructor(
        private userService: UserService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService,
        private coverageService: CoverageService,
        private currencyPipe: CurrencyPipe
    ) {
        super();
    }

    ngOnInit() {
        const userId = this.userService.user.value.id;
        this.columnChange$.pipe(debounceTime(1000)).subscribe((event: ColumnMovedEvent | ColumnResizedEvent | ColumnVisibleEvent) => {
            this.storage.setItem<ColumnState[]>(`coverage-table-columns-${userId}`, this.gridApi.getColumnState());
        });
        this.paginationPageSize = this.storage.getItem<number>(`coverage-table-size-${userId}`, 20);
    }

    update() {
        this.loading = true;
        const params = new CoverageAllRequestParams()
        params.page = this.currentPageNumber - 1;
        params.size = this.paginationPageSize;
        if (this.filter) {
            params.networksIds = this.filter.networksIds;
            params.productsIds = this.filter.productsIds;
        }
        this.$update = this.coverageService.all(params)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(page => {
                this.rows = page.content;
                this.totalRowsCount = page.totalElements;
                this.loading = false;
            });
    }

    private onAction(action: CoverageTableAction, params: ICellRendererParams<CoverageView>) {
        if (action === 'edit') {
            this.editModel = coverageViewToUpdate(params.data);
            this.coverageModal = this.modalService.alert().dialogClass('modal-dialog large-modal').component(this.coverageModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.coverageService.delete([params.data.id]).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Coverage deleted', 'Coverage');
                    this.update();
                });
            });
        }
    }

    private onCellClickEnabled(event: CellClickedEvent<CoverageView>) {
        this.loading = true;
        const model = coverageViewToUpdate(event.data)
        model.enabled = !event.data.enabled;
        this.coverageService.save(model).pipe(
            catchError(e => {
                this.loading = false;
                return throwError(() => e);
            })
        ).subscribe(page => {
            this.update();
        });
    }

    createCoverage() {
        this.editModel = this.coverageService.create();
        this.coverageModal = this.modalService.alert().dialogClass('modal-dialog large-modal').component(this.coverageModalTpl).open();
    }

    onAfterSaveRouting() {
        this.coverageModal.close();
        this.update();
    }

    changeSize($event, size: number) {
        this.paginationPageSize = size;
        const userId = this.userService.user.value.id;
        this.storage.setItem<number>(`coverage-table-size-${userId}`, size);
        this.update();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type CoverageTableAction = 'edit'|'delete';
export type CoverageTableActionFun = (action: CoverageTableAction, params: ICellRendererParams<CoverageView>) => void;