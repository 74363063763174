import { EnvDef, defaults } from "./model";

export const environment: EnvDef = {
    production: false,
    releaseVersion: "%RELEASE_VERSION%",
    apiUrl: "https://api.dev.mp.telqtele.com",
    frontendUrl: "https://app.dev.mp.telqtele.com",
    keycloakUrl: "https://api.dev.mp.telqtele.com/oauth2/authorization/keycloak",
    storagePrefix: defaults.storagePrefix,
    statusPageId: defaults.statusPageId,
    agGridLicenseKey: "%AG_GRID_LICENSE_KEY%",
    forcedUpdate: false,
    postponeUpdates: [],
    recaptchaSiteKey: defaults.recaptchaSiteKey,
    recaptchaBaseUrl: defaults.recaptchaBaseUrl,
    ignoreUpdateUserIds: new Set([]),
};
