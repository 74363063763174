import { Routes } from "@angular/router";
import { ApikeyClientPageComponent } from "./api/apikey-client-page/apikey-client-page.component";
import { LoginComponent } from "./auth/login/login.component";
import { PendingPageComponent } from "./auth/pending-page/pending-page.component";
import { CoveragePageComponent } from "./coverage/coverage-page/coverage-page.component";
import { Error404Component } from './help/404/error-404.component';
import { ProductsPageComponent } from "./products/products-page/products-page.component";
import { RoutingPageComponent } from "./routing/routing-page/routing-page.component";
import { SessionsPageComponent } from "./sessions/sessions-page/sessions-page.component";
import { AuthGuard } from "./shared/auth-guard";
import { Role } from "./shared/models/user.model";
import { SmsPageComponent } from "./sms/sms-page/sms-page.component";

export interface RouteData {
    roles: Role[]
    except: Role[]
}

const admin: RouteData = { roles: [Role.ADMIN], except: [] };
const client: RouteData = { roles: [Role.USER], except: [Role.ADMIN] };
const all: RouteData = { roles: [Role.USER, Role.ADMIN], except: [] };

export const routes: Routes = [
    { path: 'login', component: LoginComponent },
    { path: 'pending', component: PendingPageComponent },
    { path: 'sms', component: SmsPageComponent, canActivate: [AuthGuard], data: all },
    {
        path: 'networks', canActivate: [AuthGuard], data: all,
        loadChildren: () => import('./networks/networks.module').then(m => m.NetworksModule),
    },
    { path: 'sessions', component: SessionsPageComponent, canActivate: [AuthGuard], data: all },
    { path: 'products', component: ProductsPageComponent, canActivate: [AuthGuard], data: all },
    { path: 'products/coverage', component: CoveragePageComponent, canActivate: [AuthGuard], data: all },
    { path: 'routing', component: RoutingPageComponent, canActivate: [AuthGuard], data: client },
    {
        path: 'pending-users', canActivate: [AuthGuard], data: admin,
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
    },
    { path: 'api/client', component: ApikeyClientPageComponent, canActivate: [AuthGuard], data: client },
    {
        path: 'invoice', canActivate: [AuthGuard], data: all,
        loadChildren: () => import('./invoice/invoice.module').then(m => m.InvoiceModule),
    },
    {
        path: 'plan', canActivate: [AuthGuard], data: all,
        loadChildren: () => import('./plan/plan.module').then(m => m.PlanModule)
    },
    {
        path: 'mnp', canActivate: [AuthGuard], data: client,
        loadChildren: () => import('./mnp/mnp.module').then(m => m.MnpRoutingModule),
    },
    { path: '', redirectTo: '/sms', pathMatch: 'full' },
    { path: '**', pathMatch: 'full', component: Error404Component },
];
