export interface EnvDef {
    production: boolean;
    releaseVersion: string;
    storagePrefix: string;
    apiUrl: string;
    frontendUrl: string;
    keycloakUrl: string;
    statusPageId: string;
    forcedUpdate: boolean;
    postponeUpdates: string[];
    ignoreUpdateUserIds: Set<string>;
    agGridLicenseKey: string;
    recaptchaSiteKey: string;
    recaptchaBaseUrl: string;
}

export const defaults = {
    storagePrefix: "mp_",
    releaseVersion: "default",
    apiUrl: "https://api.dev.mp.telqtele.com",
    frontendUrl: "https://app.dev.mp.telqtele.com",
    statusPageId: "8mnnnzvs0gsr",
    forcedUpdate: false,
    postponeUpdates: [],
    recaptchaSiteKey: "6LcTiYMlAAAAAO6gzYFOXtRPErMzf0SqKOMTiFB4",
    recaptchaBaseUrl: "https://recaptcha.net/recaptcha/api.js",
    ignoreUpdateUserIds: new Set([]),
};
