import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { MnpProduct, MnpProductAllRequestParams, MnpProductUpdate } from "../models/mnp-product.model";
import { Country, NetworkDetails } from "../models/network.model";
import { MnpCoverage, MnpCoverageAllRequestParams, MnpCoverageUpdate } from "../models/mnp-coverage.model";

@Injectable({
    providedIn: 'root'
})
export class MnpCoverageService {

    constructor( private http: HttpClient) {}

    all(params: MnpCoverageAllRequestParams): Observable<Page<MnpCoverage>> {
        const query = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            countriesIds: params.countriesIds.length ? params.countriesIds.join(',') : '',
            productsIds: params.productsIds.length ? params.productsIds.join(',') : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/number-intelligence/%ROLE%/coverages`;
        return this.http.get<ApiResponse<Page<MnpCoverage>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: MnpCoverageUpdate): Observable<MnpCoverageUpdate> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/coverages`;
        if (model.id) {
            return this.http.put<ApiResponse<MnpCoverageUpdate>>(url, model, {withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        return this.http.post<ApiResponse<MnpCoverageUpdate>>(url, model, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    toggleActiveMnp(id: number): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/coverages/${id}/toggle-active-mnp`;
        return this.http.post<ApiResponse<void>>(url, null, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/coverages?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    countries(): Observable<Country[]> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/coverages/countries`;
        return this.http.get<ApiResponse<Country[]>>(url, {
            withCredentials: true,
        }).pipe(
            map(r => r.data)
        );
    }

    create(): MnpCoverageUpdate {
        return {
            id: null,
            priority: null,
            price: null,
            enabled: true,
            countryId: null,
            productId: null,
        };
    }

}
