
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from "../../services/user.service";

@Component({
    selector: 'app-balance-widget',
    templateUrl: 'balance-widget.component.html',
    styleUrls: ['balance-widget.component.scss']
})

export class BalanceWidgetComponent implements OnInit {

    currentValue: number = 0;

    constructor(
        public service: UserService,
        private router: Router
    ) { }

    ngOnInit() {
        this.service.balanceUpdates.subscribe(balance => {
            this.currentValue = balance;
        });
    }

    onBalanceWidgetClick(): void {

    }

}
