import { CDK_DRAG_CONFIG } from "@angular/cdk/drag-drop";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import {
    APP_INITIALIZER,
    CUSTOM_ELEMENTS_SCHEMA, DEFAULT_CURRENCY_CODE,
    NgModule,
} from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { LicenseManager } from "ag-grid-enterprise";
import {
    RECAPTCHA_BASE_URL,
    RECAPTCHA_V3_SITE_KEY
} from "ng-recaptcha";
import { ApiModule } from "./app/api/api.module";
import { AppComponent } from "./app/app/app.component";
import { LoginComponent } from "./app/auth/login/login.component";
import { CoverageModule } from "./app/coverage/coverage.module";
import { ProductsModule } from "./app/products/products.module";
import { routes } from "./app/routes";
import { RoutingModule } from "./app/routing/routing.module";
import { SessionsModule } from "./app/sessions/sessions.module";
import { HttpErrorInterceptor } from "./app/shared/services/http-error-interceptor.service";
import { HttpUrlInterceptor } from "./app/shared/services/http-url.interceptor";
import { UserService } from "./app/shared/services/user.service";
import { SharedModule } from "./app/shared/shared.module";
import { SmsModule } from "./app/sms/sms.module";
import { environment } from "./environments/environment";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MnpModule } from "./app/mnp/mnp.module";

export function initApp(userService: UserService) {
    return () => {
        return new Promise((resolve, reject) => {
            userService.init().then(() => {
                resolve(true);
            });
        });
    };
}

if (environment.agGridLicenseKey) {
    LicenseManager.setLicenseKey(environment.agGridLicenseKey);
}

@NgModule({
    bootstrap: [AppComponent],
    declarations: [AppComponent, LoginComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(routes, {
            useHash: true,
        }),
        SharedModule,
        SessionsModule,
        ProductsModule,
        RoutingModule,
        SmsModule,
        ApiModule,
        CoverageModule
    ],
    providers: [
        { provide: "API_URL", useValue: environment.apiUrl },
        { provide: "FRONTEND_URL", useValue: environment.frontendUrl },
        { provide: "KEYCLOAK_URL", useValue: environment.keycloakUrl },
        { provide: "LOCAL_STORAGE_PREFIX", useValue: environment.storagePrefix },
        { provide: "STATUSPAGE_ID", useValue: environment.statusPageId },
        { provide: "RELEASE_VERSION", useValue: environment.releaseVersion },
        { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptchaSiteKey },
        { provide: RECAPTCHA_BASE_URL, useValue: environment.recaptchaBaseUrl }, // because google does not work in China ...
        { provide: CDK_DRAG_CONFIG, useValue: { zIndex: 10000 } },
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [UserService],
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: HttpUrlInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: HttpErrorInterceptor, multi: true },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'EUR' }
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class MainModule { }
