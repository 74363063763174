import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { SharedModule } from "../shared/shared.module";
import { BrowserModule, Title } from "@angular/platform-browser";
import { AgGridModule } from "ag-grid-angular";
import { DatePipe } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CoverageFilterComponent } from "./coverage-filter/coverage-filter.component";
import { CoverageTableActionsRendererComponent } from "./coverage-table/coverage-table-actions-renderer.component";
import { CoverageTableComponent } from "./coverage-table/coverage-table.component";
import { CoveragePageComponent } from "./coverage-page/coverage-page.component";
import { CoverageFormComponent } from "./coverage-form/coverage-form.component";

@NgModule({
    declarations: [
        CoverageFilterComponent, CoverageTableActionsRendererComponent, CoverageTableComponent, CoveragePageComponent, CoverageFormComponent
    ],
    providers: [DatePipe, Title],
    imports: [SharedModule, BrowserModule, AgGridModule, FormsModule, ReactiveFormsModule, DragDropModule, ScrollingModule],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class CoverageModule {

}