import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { CoverageAllRequestParams, CoverageUpdate, CoverageView } from "../models/coverage.model";

@Injectable({
    providedIn: 'root'
})
export class CoverageService {

    constructor(private http: HttpClient) {}

    all(params: CoverageAllRequestParams): Observable<Page<CoverageView>> {
        const query = {
            page: params.page,
            size: params.size,
            networksIds: params.networksIds.length ? params.networksIds.join(',') : '',
            productsIds: params.productsIds.length ? params.productsIds.join(',') : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/routing/%ROLE%/coverages`;
        return this.http.get<ApiResponse<Page<CoverageView>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: CoverageUpdate): Observable<CoverageUpdate> {
        if (model.id) {
            const url = `%BASE_URL%/routing/%ROLE%/coverages/${model.id}`;
            return this.http.put<ApiResponse<CoverageUpdate>>(url, model,{withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        const url = `%BASE_URL%/routing/%ROLE%/coverages`;
        return this.http.post<ApiResponse<CoverageUpdate>>(url, model,{withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/routing/%ROLE%/coverages?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): CoverageUpdate {
        return {
            id: null,
            productId: null,
            networkId: null,
            enabled: true,
            price: 0,
            currency: 'EUR',
            organizationId: 0
        };
    }

}


