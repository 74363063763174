import { DragDropModule } from "@angular/cdk/drag-drop";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule, CurrencyPipe } from "@angular/common";
import { HttpClientModule } from "@angular/common/http";
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { Error403Component } from "../help/403/error-403.component";
import { Error404Component } from "../help/404/error-404.component";
import { OfflineComponent } from "../help/offline/offline.component";
import { TourComponent } from "../help/tour/tour.component";
import { UpdateDetectComponent } from "../help/update-detect/update-detect.component";
import { MainMenuComponent } from "../main-menu/main-menu.component";
import { ProfileComponent } from "../main-menu/profile/profile.component";
import { AuthGuard } from "./auth-guard";
import { AgTooltipComponent } from "./components/ag-tooltip/ag-tooltip.component";
import { Breadcrumbs, BreadcrumbService } from "./components/breadcrumbs/breadcrumbs";
import { FormErrors } from "./components/error/formErrors";
import { InputDateTime } from "./components/input/input.dateTime";
import { InputDuration } from "./components/input/input.duration";
import { InputErrors } from "./components/input/input.errors";
import { InputFile } from "./components/input/input.file";
import { InputSearch } from "./components/input/input.search";
import { InputSearchDatePeriod } from "./components/input/input.search.datePeriod";
import { ModalsWidgetComponent } from "./components/modals-widget/modals-widget.component";
import { NetworksSelectComponent } from "./components/networks-select/networks-select.component";
import { NotificationsWidgetComponent } from "./components/notifications-widget/notifications-widget.component";
import { PaginationComponent } from "./components/pagination/pagination.component";
import { ProductsSelectComponent } from "./components/products-select/products-select.component";
import { Progressbar } from "./components/progress/progressbar.component";
import { SlideButtonComponent } from "./components/slide-button/slide-button.component";
import { SuppliersSelectComponent } from "./components/suppliers-select/suppliers-select.component";
import { DropdownDirective } from "./directives/dropdown.directive";
import { ScrollSpyDirective } from "./directives/scroll-spy-directive.directive";
import { TooltipDirective } from "./directives/tooltip.directive";
import { DurationPipe } from "./pipes/duration.pipe";
import { FileSizePipe } from "./pipes/fileSize.pipe";
import { FilterTextPipe } from "./pipes/filter.text.pipe";
import { InitialsPipe } from "./pipes/initials.pipe";
import { NumberFormatPipe } from "./pipes/number-format.pipe";
import { SortPipe } from "./pipes/sort.pipe";
import { ThousandSeparator } from "./pipes/thousandseparator.pipe";
import { TruncatePipe } from "./pipes/truncate.pipe";
import { HttpErrorInterceptor } from "./services/http-error-interceptor.service";
import { HttpUrlInterceptor } from "./services/http-url.interceptor";
import { LocalStorage } from "./services/local-storage.service";
import { ModalService } from "./services/modal.service";
import { NotificationService } from "./services/notification.service";
import { StatusPageService } from "./services/status-page.service";
import { UserService } from "./services/user.service";
import { ValidationService } from "./services/validation.service";
import { CountriesSelectComponent } from "./components/countries-select/countries-select.component";
import { MnpProductsSelectComponent } from "./components/mnp-products-select/mnp-products-select.component";
import { BalanceWidgetComponent } from "./components/balance-widget/balance-widget.component";


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        DragDropModule,
        RouterModule,
        ScrollingModule
    ],
    declarations: [
        UpdateDetectComponent,
        Breadcrumbs,
        PaginationComponent,
        Progressbar,
        FormErrors,
        FilterTextPipe,
        InputSearch,
        SortPipe,
        ThousandSeparator,
        FileSizePipe,
        TruncatePipe,
        InputSearchDatePeriod,
        InputErrors,
        InputDuration,
        InputDateTime,
        InputFile,
        DurationPipe,
        TourComponent, 
        Error404Component, 
        Error403Component,
        NotificationsWidgetComponent, 
        OfflineComponent,
        ModalsWidgetComponent,
        TooltipDirective,
        ProfileComponent,
        InitialsPipe,
        NumberFormatPipe,
        ScrollSpyDirective,
        DropdownDirective,
        AgTooltipComponent,
        SlideButtonComponent,
        MainMenuComponent,
        NetworksSelectComponent,
        ProductsSelectComponent,
        SuppliersSelectComponent,
        CountriesSelectComponent,
        MnpProductsSelectComponent,
        BalanceWidgetComponent
    ],
    providers: [
        LocalStorage,
        ValidationService,
        StatusPageService,

        AuthGuard,
        UserService,

        NotificationService,
        ModalService,
        BreadcrumbService,

        HttpUrlInterceptor,
        HttpErrorInterceptor,
        CurrencyPipe,
        NumberFormatPipe,
    ],
    exports: [
        UpdateDetectComponent,
        Breadcrumbs,
        PaginationComponent,
        Progressbar,
        FormErrors,
        FilterTextPipe,
        InputSearch,
        SortPipe,
        ThousandSeparator,
        FileSizePipe,
        TruncatePipe,
        InputSearchDatePeriod,
        InputErrors,
        InputDuration,
        InputDateTime,
        InputFile,
        DurationPipe,
        // help
        TourComponent, Error404Component, Error403Component,
        NotificationsWidgetComponent, OfflineComponent,

        ModalsWidgetComponent,

        TooltipDirective,
        ProfileComponent,
        InitialsPipe,
        NumberFormatPipe,
        ScrollSpyDirective,
        DropdownDirective,
        AgTooltipComponent,
        SlideButtonComponent,
        MainMenuComponent,
        NetworksSelectComponent,
        ProductsSelectComponent,
        SuppliersSelectComponent,
        CountriesSelectComponent,
        MnpProductsSelectComponent,
        BalanceWidgetComponent
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {

}