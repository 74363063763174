import { Supplier } from "./supplier.model";

export interface MnpProduct {
    id?: number;
    name?: string;
    enabled: boolean;
    supplier: Supplier;
    config: MnpProductConfigHttp;
}

export interface MnpProductConfigHttp {
    protocol: 'HTTP';
    method: string;
    url: string;
    mcc: string;
    mnc: string;
    responseType: 'application/json';
    requestStatus: string;
    timeout: number;
}

export interface MnpProductUpdate {
    id?: number;
    name?: string;
    enabled: boolean;
    supplierId?: number;
    config: MnpProductConfigHttp;
}

export function productToProductUpdate(p: MnpProduct): MnpProductUpdate {
    let config = p.config;
    if (!config.protocol) {config.protocol = 'HTTP';}
    if (!config.responseType) {config.responseType = 'application/json';}
    if (!config.method) {config.method = 'GET';}
    return {
        id: p.id,
        name: p.name,
        supplierId: p.supplier.id,
        enabled: p.enabled,
        config: config
    }
}

export class MnpProductAllRequestParams {

    size: number = 20;
    page: number = 0;
    search: string;

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}