import {
    AfterViewInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    TemplateRef,
    ViewChild
} from "@angular/core";
import { AgGridUtils } from "../../shared/services/ag-grid-utils";
import {
    ColumnMovedEvent, ColumnResizedEvent,
    ColumnState, ColumnVisibleEvent, GetMainMenuItemsParams,
    GridApi, GridOptions, ICellRendererParams, SortChangedEvent
} from "ag-grid-community";
import { UserService } from "../../shared/services/user.service";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { catchError, debounceTime, distinctUntilChanged, fromEvent, Subscription, tap, throwError } from "rxjs";
import { SuppliersTableActionsRendererComponent } from "./suppliers-table-actions-renderer.component";
import { DialogRef, ModalService } from "../../shared/services/modal.service";
import { NotificationService } from "../../shared/services/notification.service";
import { Supplier, SupplierAllRequestParams } from "../../shared/models/supplier.model";
import { SupplierService } from "../../shared/services/supplier.service";
import { AGTableBase } from "../../shared/components/table/ag-table-base";
import { ProductAllRequestParams } from "../../shared/models/product.model";

@Component({
    selector: 'app-suppliers-table',
    templateUrl: './suppliers-table.component.html',
    styleUrls: [],
})
export class SuppliersTableComponent extends AGTableBase implements OnInit, AfterViewInit, OnDestroy {

    theme = AgGridUtils.theme;
    gridApi: GridApi<Supplier>;

    gridOptions: GridOptions<Supplier> = {
        onGridReady: e => {
            this.gridApi = e.api;
            this.update();
        },
        columnDefs: [
            {
                headerName: 'Name',
                valueGetter: (params) => params.data.name,
                sortable: false,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                resizable: false,
                getQuickFilterText: params => params.data.name
            },
            {
                headerName: 'Actions',
                maxWidth: 150,
                minWidth: 150,
                sortable: false,
                pinned: 'right',
                lockPinned: true,
                lockPosition: 'right',
                lockVisible: true,
                suppressColumnsToolPanel: true,
                suppressMenu: true,
                suppressAutoSize: true,
                headerClass: 'action-cell',
                cellClass: 'action-cell',
                cellRenderer: SuppliersTableActionsRendererComponent,
                cellRendererParams: {
                    onAction: (action: SuppliersTableAction, params: ICellRendererParams<Supplier>) => this.onAction(action, params),
                },
                getQuickFilterText: params => '',
            }
        ],
        domLayout: 'autoHeight',
        rowModelType: 'clientSide',
        popupParent: document.body,
        suppressMenuHide: true,
        suppressDragLeaveHidesColumns: true,
        tooltipShowDelay: 300,
        autoSizeStrategy: {
            type: 'fitGridWidth'
        }
    };

    rows: Supplier[] = [];
    loading = false;
    private $update: Subscription;

    @ViewChild('supFormModalTpl', { read: TemplateRef, static: false }) supFormModalTpl: any;
    supplierModel: Supplier;
    supplierFormModal: DialogRef;

    @Input() selectMode = false;

    @ViewChild('searchInput') searchInput: ElementRef;
    searchValue = '';

    constructor(
        private userService: UserService,
        private supplierService: SupplierService,
        private storage: LocalStorage,
        private modalService: ModalService,
        private notifications: NotificationService
    ) {
        super();

    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        fromEvent(this.searchInput.nativeElement,'input')
            .pipe(
                debounceTime(300),
                distinctUntilChanged(),
                tap(() => {
                    this.searchValue = this.searchInput.nativeElement.value.trim();
                    this.update();
                })
            ).subscribe();
    }

    update() {
        this.loading = true;
        const params = new SupplierAllRequestParams()
        params.page = this.currentPageNumber - 1;
        params.size = this.paginationPageSize;
        params.search = this.searchValue;
        this.$update = this.supplierService.all(params)
            .pipe(
                catchError(e => {
                    this.loading = false;
                    return throwError(() => e);
                })
            )
            .subscribe(rows => {
                this.rows = rows.content;
                this.loading = false;
            });
    }

    private onAction(action: SuppliersTableAction, params: ICellRendererParams<Supplier>) {
        if (action === 'edit') {
            this.supplierModel = Object.assign({}, params.data);
            this.supplierFormModal = this.modalService.alert().isBlocking(true).component(this.supFormModalTpl).open();
        }
        if (action === 'delete') {
            this.modalService.confirm().then(confirm => {
                if (!confirm) {return;}
                this.loading = true;
                this.supplierService.delete(params.data.id).pipe(
                    catchError(e => {
                        this.loading = false;
                        return throwError(() => e);
                    })
                ).subscribe(() => {
                    this.notifications.success('Supplier deleted', 'Products');
                    this.update();
                });
            });
        }
    }

    onAfterSave() {
        this.supplierFormModal.close();
        this.update();
    }

    onCreateSupplier() {
        this.supplierModel = {
            id: null,
            name: '',
        }
        this.supplierFormModal = this.modalService.alert().isBlocking(true).component(this.supFormModalTpl).open();
    }

    ngOnDestroy() {
        if (this.$update && !this.$update.closed) {
            this.$update.unsubscribe();
        }
    }
}

export type SuppliersTableAction = 'edit'|'delete';
export type SuppliersTableActionFun = (action: SuppliersTableAction, params: ICellRendererParams<Supplier>) => void;