import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { Supplier, SupplierAllRequestParams } from "../models/supplier.model";

@Injectable({
    providedIn: 'root'
})
export class MnpSupplierService {

    constructor( private http: HttpClient) {}

    all(): Observable<Supplier[]> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/suppliers`;
        return this.http.get<ApiResponse<Supplier[]>>(url, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    save(model: Supplier): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/suppliers`;
        if (model.id) {
            return this.http.put<void>(url, model,{withCredentials: true});
        }
        return this.http.post<void>(url, model,{withCredentials: true});
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/suppliers?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): Supplier {
        return {
            id: null,
            name: '',
        };
    }

}
