import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    OnDestroy, OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { RouteView } from "../../shared/models/routing.model";
import { RoutingService } from "../../shared/services/routing.service";
import { CurrencyPipe } from "@angular/common";

@Component({
    selector: 'app-routes-select',
    templateUrl: 'routes-select.component.html',
    styleUrls: ['routes-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RoutesSelectComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() selected: number[] = [];
    @Input() networkId: number = null;
    @Input() multiple: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() disabledAll = false;
    @Output() onChange = new EventEmitter<RouteView[]>;

    @ViewChild('destinationWrapper') destinationWrapper: ElementRef;
    rowsVisible = 8;
    rowsVisibleForFilters = 15;

    tab: 'all'|'selected' = 'all';

    @HostListener('window:resize')
    onResize() {
        if (!this.destinationWrapper) { return; }
        const height = this.destinationWrapper.nativeElement.clientHeight - 30;
        if (!height && height <= 0) { return; }
        const rows = Math.max(Math.floor(height / 30), 8);
        if (isNaN(rows)) {
            console.error('Destination list Invalid size: ', rows);
        } else {
            if (rows !== this.rowsVisible) {
                this.rowsVisible = rows;
                this.cdr.detectChanges();
            }
        }
    }

    @ViewChild('scrollViewportSelected') private cdkVirtualScrollViewportSelected: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportAll') private cdkVirtualScrollViewportAll: CdkVirtualScrollViewport;
    onChangeTab(tab: 'all'|'selected') {
        this.tab = tab;
        setTimeout(() => {
            this.cdkVirtualScrollViewportSelected.checkViewportSize();
            this.cdkVirtualScrollViewportAll.checkViewportSize();
        }, 100);
    }

    models: RouteView[] = [];
    toggleAll: boolean = false;
    searchQuery: string = '';

    selectedCount = 0;
    maxSelectedCount = 100;

    loading: boolean = false;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    constructor(
        private cdr: ChangeDetectorRef,
        private routingService: RoutingService,
        private currencyPipe: CurrencyPipe
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.onResize();
        this.update();
    }

    getSelectedModels(): RouteView[] {
        return this.models.filter(_ => _.selected);
    }

    getModels(): RouteView[] {
        return this.models;
    }

    update() {
        this.loading = true;
        this.routingService.routes(this.networkId).subscribe(routes => {
            this.selectedCount = 0;
            this.models = routes.map(d => {
                this.prepareModel(d);
                return d;
            });
            this.loading = false;
            this.cdr.detectChanges();
        });
    }

    private prepareModel(model: RouteView) {
        model.selected = this.selected.includes(model.productId);
        model.__filterTextResult = false;
        if (typeof model.__search === 'undefined') {
            model.__search = [
                model.supplierName,
                model.productName,
            ].map(s => s ? (String(s)).trim() : '').join('').toLowerCase();
        }
    }

    reset() {
        if (this.disabledAll) {return;}
        this.selectedCount = 0;
        this.models.forEach(_ => _.selected = false);
        this.selected = [];
        this.onChange.emit([]);
        this.toggleAll = false;
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }
        this.searchQuery = '';
        this.cdr.detectChanges();
    }

    onClickToggleAll() {
        if (!this.multiple || this.disabledAll) {return;}
        this.toggleAll = !this.toggleAll;
        let hasQuery = typeof this.searchQuery !== 'undefined' && !!this.searchQuery;
        let selectedCount = this.models.filter(_ => _.selected).length;
        this.getModels().filter(c => {
            if (hasQuery) {
                return c.__filterTextResult;
            }
            return true;
        }).forEach(c => {
            if (this.toggleAll) {
                selectedCount++;
            } else {
                selectedCount--;
            }
            if (this.toggleAll && selectedCount > this.maxSelectedCount) {
                return;
            }
            c.selected = this.toggleAll;
        });
        this.selectedCount = this.models.filter(_ => _.selected).length;
        const selectedModels =  this.getSelectedModels();
        this.selected = selectedModels.map(_ => _.productId);
        this.onChange.emit(selectedModels);
        this.cdr.detectChanges();
    }

    toggle(model: RouteView) {
        if (this.disabledAll) {return;}
        const selected = !model.selected;
        if (!this.multiple && selected) {
            this.models.forEach(m => m.selected = false)
        }
        model.selected = selected;
        this.toggleAll = (this.selectedCount === this.maxSelectedCount) || this.selectedCount === this.models.length
        const selectedModels =  this.getSelectedModels();
        this.selected = selectedModels.map(_ => _.productId);
        this.onChange.emit(selectedModels);
        this.cdr.detectChanges();
    }

    onSearchQueryChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        if (value !== this.searchQuery) {
            this.searchQuery = value;
            this.cdr.detectChanges();
        }
    }

    getTooltip(d: RouteView) {
        const tooltipText = `${d.supplierName} (${d.productName}) / ${this.currencyPipe.transform(d.price, d.currency, true, '1.0-4')})`;
        if (tooltipText.length < 50) return null;
        return tooltipText;
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.networkId && !changes.networkId.isFirstChange()) {
            this.reset();
            this.update();
        }
    }
}



