import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { ApiResponse, Page } from "../models/utils";
import { Country, Network, NetworkDetails, NetworkUpdate, Operator, Prefix } from "../models/network.model";
import { map } from "rxjs/operators";
import { Organization } from "../models/user.model";

@Injectable({providedIn: 'root'})
export class NetworkService {
    constructor(private http: HttpClient) {
    }

    allNetworks(page: number, size: number, search: string): Observable<Page<Network>> {
        const url = `%BASE_URL%/number-intelligence/client/networks`;
        return this.http.get<ApiResponse<Page<Network>>>(url, {
            withCredentials: true,
            params: {page: page, size: size, search: search}
        }).pipe(
            map(r => r.data)
        );
    }

    deleteNetwork(id: number): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/networks/${id}`;
        return this.http.delete<void>(url, {
            withCredentials: true
        })
    }

    allCountries(): Observable<Country[]> {
        const url = `%BASE_URL%/number-intelligence/client/countries`;
        return this.http.get<ApiResponse<Country[]>>(url, {
            withCredentials: true,
        }).pipe(
            map(r => r.data)
        );
    }

    saveNetwork(model: NetworkUpdate): Observable<NetworkUpdate> {
        if (model.id) {
            return this.http.put<ApiResponse<NetworkUpdate>>(`%BASE_URL%/number-intelligence/%ROLE%/networks`, model, {withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        return this.http.post<ApiResponse<NetworkUpdate>>(`%BASE_URL%/number-intelligence/%ROLE%/networks`, model, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    saveCountry(model: Country): Observable<Country> {
        if (model.id) {
            return this.http.put<ApiResponse<Country>>(`%BASE_URL%/number-intelligence/%ROLE%/countries`, model, {withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        return this.http.post<ApiResponse<Country>>(`%BASE_URL%/number-intelligence/%ROLE%/countries`, model, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    deleteCountry(id: number): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/countries/${id}`;
        return this.http.delete<void>(url, {
            withCredentials: true
        })
    }

    allOperators(): Observable<Operator[]> {
        const url = `%BASE_URL%/number-intelligence/client/operators`;
        return this.http.get<ApiResponse<Operator[]>>(url, {
            withCredentials: true,
        }).pipe(
            map(r => r.data)
        );
    }

    saveOperator(model: Operator): Observable<Operator> {
        if (model.id) {
            return this.http.put<ApiResponse<Operator>>(`%BASE_URL%/number-intelligence/%ROLE%/operators`, model, {withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        return this.http.post<ApiResponse<Operator>>(`%BASE_URL%/number-intelligence/%ROLE%/operators`, model, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    deleteOperator(id: number): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/operators/${id}`;
        return this.http.delete<void>(url, {
            withCredentials: true
        })
    }

    allPrefixesByNetworkId(networkId: number, lastId: number, size: number = 1000): Observable<Prefix[]> {
        const url = `%BASE_URL%/number-intelligence/client/networks/${networkId}/prefixes`;
        return this.http.get<ApiResponse<Prefix[]>>(url, {
            withCredentials: true,
            params: {lastId: lastId, size: size}
        }).pipe(
            map(r => r.data)
        );
    }

    savePrefix(model: Prefix): Observable<Prefix> {
        if (model.id) {
            return this.http.put<ApiResponse<Prefix>>(`%BASE_URL%/number-intelligence/%ROLE%/prefixes`, model, {withCredentials: true}).pipe(
                map(r => r.data)
            );
        }
        return this.http.post<ApiResponse<Prefix>>(`%BASE_URL%/number-intelligence/%ROLE%/prefixes`, model, {withCredentials: true}).pipe(
            map(r => r.data)
        );
    }

    deletePrefix(id: number): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/prefixes/${id}`;
        return this.http.delete<void>(url, {
            withCredentials: true
        })
    }

    networkList(): Observable<NetworkDetails[]> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/network-list`;
        return this.http.get<ApiResponse<NetworkDetails[]>>(url, {
            withCredentials: true,
        }).pipe(
            map(r => r.data)
        );
    }
}