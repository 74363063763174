import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input, OnChanges,
    OnDestroy, OnInit,
    Output, SimpleChanges,
    ViewChild
} from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { ProductService } from "../../services/product.service";
import { Product, ProductAllRequestParams } from "../../models/product.model";

@Component({
    selector: 'app-products-select',
    templateUrl: 'products-select.component.html',
    styleUrls: ['products-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProductsSelectComponent implements OnInit, AfterViewInit, OnChanges {

    @Input() selected: number[] = [];
    @Input() multiple: boolean = true;
    @Input() showTitle: boolean = true;
    @Input() disabledAll = false;
    @Output() onChange = new EventEmitter<ProductSelect[]>;

    tab: 'all'|'selected' = 'all';

    @ViewChild('destinationWrapper') destinationWrapper: ElementRef;
    rowsVisible = 8;
    rowsVisibleForFilters = 15;

    @HostListener('window:resize')
    onResize() {
        if (!this.destinationWrapper) { return; }
        const height = this.destinationWrapper.nativeElement.clientHeight - 30;
        if (!height && height <= 0) { return; }
        const rows = Math.max(Math.floor(height / 30), 8);
        if (isNaN(rows)) {
            console.error('Destination list Invalid size: ', rows);
        } else {
            if (rows !== this.rowsVisible) {
                this.rowsVisible = rows;
                this.cdr.detectChanges();
            }
        }
    }

    @ViewChild('scrollViewportSelected') private cdkVirtualScrollViewportSelected: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportAll') private cdkVirtualScrollViewportAll: CdkVirtualScrollViewport;
    onChangeTab(tab: 'all'|'selected') {
        this.tab = tab;
        setTimeout(() => {
            this.cdkVirtualScrollViewportSelected.checkViewportSize();
            this.cdkVirtualScrollViewportAll.checkViewportSize();
        }, 100);
    }

    models: ProductSelect[] = [];
    toggleAll: boolean = false;
    searchQuery: string = '';

    selectedCount = 0;
    maxSelectedCount = 100;

    loading: boolean = false;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    constructor(
        private cdr: ChangeDetectorRef,
        private productService: ProductService,
    ) {
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        this.onResize();
        this.update();
    }

    getSelectedModels(): ProductSelect[] {
        return this.models.filter(_ => _.selected);
    }

    getModels(): ProductSelect[] {
        return this.models;
    }

    update() {
        this.loading = true;
        let params = new ProductAllRequestParams()
        params.size = 1000
        this.productService.all(params).subscribe(page => {
            this.selectedCount = 0;
            this.models = page.content.map(_ => this.prepareModel(_));
            this.loading = false;
            this.cdr.detectChanges();
        });
    }

    private prepareModel(model: Product): ProductSelect {
        return {
            product: model,
            selected: this.selected.includes(model.id),
            __search: [model.supplierName, model.name,].map(s => s ? (String(s)).trim() : '').join('').toLowerCase(),
            __filterTextResult: false
        }
    }

    reset() {
        if (this.disabledAll) {return;}
        this.selectedCount = 0;
        this.models.forEach(_ => _.selected = false);
        this.selected = [];
        this.onChange.emit([]);
        this.toggleAll = false;
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }
        this.searchQuery = '';
        this.cdr.detectChanges();
    }

    onClickToggleAll() {
        if (!this.multiple || this.disabledAll) {return;}
        this.toggleAll = !this.toggleAll;
        let hasQuery = typeof this.searchQuery !== 'undefined' && !!this.searchQuery;
        let selectedCount = this.models.filter(_ => _.selected).length;
        this.getModels().filter(c => {
            if (hasQuery) {
                return c.__filterTextResult;
            }
            return true;
        }).forEach(c => {
            if (this.toggleAll) {
                selectedCount++;
            } else {
                selectedCount--;
            }
            if (this.toggleAll && selectedCount > this.maxSelectedCount) {
                return;
            }
            c.selected = this.toggleAll;
        });
        this.selectedCount = this.models.filter(_ => _.selected).length;
        const selectedModels =  this.getSelectedModels();
        this.selected = selectedModels.map(_ => _.product.id);
        this.onChange.emit(selectedModels);
        this.cdr.detectChanges();
    }

    toggle(model: ProductSelect) {
        if (this.disabledAll) {return;}
        const selected = !model.selected;
        if (!this.multiple && selected) {
            this.models.forEach(m => m.selected = false)
        }
        model.selected = selected;
        this.toggleAll = (this.selectedCount === this.maxSelectedCount) || this.selectedCount === this.models.length
        const selectedModels =  this.getSelectedModels();
        this.selected = selectedModels.map(_ => _.product.id);
        this.onChange.emit(selectedModels);
        this.cdr.detectChanges();
    }

    onSearchQueryChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        if (value !== this.searchQuery) {
            this.searchQuery = value;
            this.cdr.detectChanges();
        }
    }

    getTooltip(d: ProductSelect) {
        const tooltipText = `${d.product.supplierName} (${d.product.name})`;
        if (tooltipText.length < 50) return null;
        return tooltipText;
    }

    ngOnChanges(changes: SimpleChanges) {

    }
}

export interface ProductSelect {
    product: Product
    selected: boolean
    __filterTextResult: boolean
    __search: string
}


