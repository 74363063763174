import { Title } from "@angular/platform-browser";
import { AfterViewInit, Component, ElementRef, TemplateRef, ViewChild } from "@angular/core";
import { ModalService } from "../../shared/services/modal.service";
import { UserService } from "../../shared/services/user.service";
import { Role } from "../../shared/models/user.model";
import { RoutingService } from "../../shared/services/routing.service";
import { CoverageTableComponent } from "../coverage-table/coverage-table.component";
import { CoverageSearch } from "../../shared/models/coverage.model";

@Component({
    selector: 'app-coverage-page',
    templateUrl: 'coverage-page.component.html'
})
export class CoveragePageComponent implements AfterViewInit {

    isAdmin = false;

    @ViewChild(CoverageTableComponent, { static: false }) table: CoverageTableComponent;

    constructor(private titleService: Title, private modal: ModalService, private userService: UserService, private routingService: RoutingService) {
        titleService.setTitle('Coverage');
        this.isAdmin = userService.user.value.roles.includes(Role.ADMIN);
    }

    ngAfterViewInit() {

    }

    onSubmitFilter(filter: CoverageSearch) {
        this.table.filter = filter;
        this.table.update();
    }

}