declare var $: any;

export class BrowserUtils {

    static isUnload = false;

    static hasCookie(name) {
        return BrowserUtils.getCookie(name) !== undefined;
    }

    static getCookie(name) {
        let matches = document.cookie.match(new RegExp(
            "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
        ));
        return matches ? decodeURIComponent(matches[1]) : undefined;
    }

    static getBrowserName() {
        let ua= navigator.userAgent, tem,
            M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
        if (/trident/i.test(M[1])){
            tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
            return 'IE '+(tem[1] || '');
        }
        if (M[1]=== 'Chrome'){
            tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
            if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
        }
        M = M[2] ? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
        if ((tem = ua.match(/version\/(\d+)/i))!= null){
            M.splice(1, 1, tem[1]);
        }

        return M.map(d => d.toLowerCase()).join(' ');
    }

    static isSafari() {
        // @ts-ignore
        return /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && window['safari'].pushNotification));
    }

    static escapeHtml(unsafe: string) {
        if (!unsafe) {
            return '';
        }
        return unsafe
            .replace(/&/g, "&amp;")
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#039;")
            .replace(/\//g, "&#x2F;");
    }

    static sanitizeHTML(text) {
        let element = document.createElement('div');
        element.innerText = text;
        return element.innerHTML;
    }

    static initPlugins() {
        // @ts-ignore
        $('body').addClass(BrowserUtils.getBrowserName());
    }

    static getXsrf() {
        let token = window.localStorage.getItem('xsrf');
        return token ? token : null;
    }

    static setXsrf(token) {
        window.localStorage.setItem('xsrf', token);
    }

    static downloadFile(blob: Blob, fileName: string) {
        const elem = window.document.createElement('a');
        elem.href = window.URL.createObjectURL(blob);
        elem.download = fileName;
        document.body.appendChild(elem);
        elem.click();
        document.body.removeChild(elem);
    }

    static copyToClipboard(text: string) {
        if (navigator.clipboard) {
            navigator.clipboard.writeText(text).then()
        } else {
            const textArea = document.createElement("textarea");
            textArea.style.position = 'fixed';
            textArea.style.top = String(0);
            textArea.style.left = String(0);
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select()
            try {
                document.execCommand('copy');
            } catch (e) {
                
            }
            document.body.removeChild(textArea);
        }
    }

    static isProd(): boolean {
        return location.hostname === 'app.telqtele.com';
    }

    static getPageWrapperHeight(): number {
        const wrapper = document.getElementsByClassName('content-wrapper').item(0) as HTMLElement;

        return wrapper.clientHeight;
    }

    static getCurrentVersion(): string {
        return window['CURRENT_VERSION'];
    }

    static getCurrentYearMonth(): string {
        const today = new Date();
        const year = today.getFullYear();
        const month = today.getMonth() + 1;
        return `${year}-${month.toString().padStart(2, '0')}`;
    }
}
