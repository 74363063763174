import { Inject, Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BehaviorSubject, forkJoin, map, Observable } from "rxjs";
import { CurrentUser, Organization, PlatformUser, Role, } from "../models/user.model";
import { ActivatedRouteSnapshot, Router } from "@angular/router";
import { ApiResponse, Page } from "../models/utils";
import { RouteData } from "../../routes";

@Injectable({ providedIn: "root" })
export class UserService {
    public isAuth = new BehaviorSubject(false);

    public user = new BehaviorSubject<CurrentUser>(null);
    private _user: CurrentUser;

    balanceUpdates: BehaviorSubject<number> = new BehaviorSubject(0);
    private balanceValue = 0;

    constructor(
        private http: HttpClient,
        private router: Router,
        @Inject("API_URL") private backendUrl: string,
        @Inject("FRONTEND_URL") private frontendUrl: string,
    ) {
        setInterval(() => {
            this.updateBalance();
        }, 15 * 1000)
        this.isAuth.subscribe(auth => {
            if(auth) {this.updateBalance()}
        });
    }

    private updateBalance() {
        if (this.isAuth) {
            this.getBalance().subscribe(currentBalance => {
                if (currentBalance !== this.balanceValue) {
                    this.balanceValue = currentBalance;
                    this.balanceUpdates.next(currentBalance);
                }
            });
        }
    }

    init(): Promise<boolean> {
        return new Promise((resolve) => {
            this.currentUser().subscribe({
                next: () => {
                    this.isAuth.next(true);
                    resolve(true);
                },
                error: (err) => resolve(true),
            });
        });
    }

    getVersion() {
        return this.http
            .get<{ current: string }>("/version.json?", { params: { t: Date.now() } })
            .pipe(map((r) => r.current));
    }

    currentUser(): Observable<CurrentUser> {
        return this.http
            .get<ApiResponse<CurrentUser>>(`%BASE_URL%/auth/me`, { withCredentials: true })
            .pipe(
                map((response) => {
                    const user = response.data;
                    this._user = user;
                    this.user.next(user);
                    return user;
                }),
            );
    }

    getBalance(): Observable<number> {
        const url = `%BASE_URL%/billing/%ROLE%/balance`;
        return this.http
            .get<ApiResponse<number>>(url, { withCredentials: true })
            .pipe(map((r) => r.data));
    }

    allOrganizations(): Observable<Organization[]> {
        const url = `%BASE_URL%/billing/%ROLE%/organizations`;
        return this.http
            .get<ApiResponse<Organization[]>>(url, { withCredentials: true })
            .pipe(
                map((r) =>
                    r.data.map((m) => {
                        this.prepareOrganization(m);
                        return m;
                    }),
                ),
            );
    }

    prepareOrganization(model: Organization) {
        model.search = model.name.toLowerCase();
    }

    saveOrganization(model: Organization): Observable<Organization> {
        if (model.id) {
            return this.http
                .put<
                    ApiResponse<Organization>
                >(`%BASE_URL%/billing/%ROLE%/organizations/${model.id}`, model, { withCredentials: true })
                .pipe(map((r) => r.data));
        }
        return this.http
            .post<
                ApiResponse<Organization>
            >(`%BASE_URL%/billing/%ROLE%/organizations`, model, { withCredentials: true })
            .pipe(map((r) => r.data));
    }

    deleteOrganization(id: number) {
        const url = `%BASE_URL%/billing/%ROLE%/organizations/${id}`;
        return this.http.delete<void>(url, { withCredentials: true });
    }

    pendingUsers(page: number, size: number): Observable<Page<PlatformUser>> {
        const url = `%BASE_URL%/billing/%ROLE%/pending-users`;
        return forkJoin([
            this.allOrganizations(),
            this.http.get<ApiResponse<Page<PlatformUser>>>(url, {
                withCredentials: true,
                params: { page: page, size: size },
            }),
        ]).pipe(
            map((results) => {
                const organizations = new Map<number, Organization>();
                results[0].forEach((o) => organizations.set(o.id, o));
                results[1].data.content = results[1].data.content.map((u) => {
                    u.organization =
                        u.organizationId && organizations.has(u.organizationId)
                            ? organizations.get(u.organizationId)
                            : null;
                    this.prepareUser(u);
                    return u;
                });
                return results[1].data;
            }),
        );
    }

    prepareUser(u: PlatformUser) {
        u.createdAt = new Date(u.createdAt);
        u.updatedAt = u.updatedAt ? new Date(u.updatedAt) : null;
    }

    assignOrganization(
        userId: string,
        organizationId: number,
    ): Observable<PlatformUser> {
        const url = `%BASE_URL%/billing/%ROLE%/users/${userId}/assign-organization/${organizationId}`;
        return this.http
            .post<ApiResponse<PlatformUser>>(url, null, { withCredentials: true })
            .pipe(map((r) => r.data));
    }

    getMonthlySmsCount(monthYear: string): Observable<number> {
        const url = `%BASE_URL%/billing/%ROLE%/organizations/monthly-count?monthYear=${monthYear}`;
        return this.http
            .get<ApiResponse<number>>(url, { withCredentials: true })
            .pipe(map((r) => r.data));
    }

    hideUser(id: string): Observable<PlatformUser> {
        const url = `%BASE_URL%/billing/%ROLE%/users/${id}/hide`;
        return this.http
            .put<ApiResponse<PlatformUser>>(url, null, { withCredentials: true })
            .pipe(map((r) => r.data));
    }

    canActivate(route: ActivatedRouteSnapshot) {
        if (!route.data) {
            return true;
        }

        if (!this._user) {
            return false;
        }

        if (!this._user.enabled && !this._user.roles.includes(Role.ADMIN)) {
            this.router.navigate(["/pending"]).then();
            return false;
        }

        const data = route.data as RouteData;
        for (const role of data.except) {
            if (this._user.roles.includes(role)) {
                return false;
            }
        }
        for (const role of data.roles) {
            if (this._user.roles.includes(role)) {
                return true;
            }
        }

        return false;
    }

    toLogin() {
        window.location.href = `${this.backendUrl}/oauth2/authorization/keycloak?redirect_url=${this.frontendUrl}`;
    }

    toLogout() {
        window.location.href = `${this.backendUrl}/auth/logout-sso?ui_url=${this.frontendUrl}`;
    }
}
