import { NetworkDetails } from "./network.model";


export interface CoverageView {
    id: number
    createdAt: string|Date|null
    productId: number
    productName: string
    supplierId: number
    supplierName: string
    price: number
    currency: string
    enabled: boolean
    networkId: number
    network: NetworkDetails
    organizationId: number
}

export interface CoverageSearch {
    networksIds: number[]
    productsIds: number[]
}

export interface CoverageUpdate {
    id: number
    productId: number
    price: number
    currency: string
    enabled: boolean
    networkId: number
    organizationId: number
}


export function coverageViewToUpdate(c: CoverageView): CoverageUpdate {
    return {
        id: c.id,
        productId: c.productId,
        networkId: c.networkId,
        enabled: c.enabled,
        price: c.price,
        currency: c.currency,
        organizationId: c.organizationId
    }
}

export class CoverageAllRequestParams {

    size: number = 20;
    page: number = 0;
    networksIds: number[] = [];
    productsIds: number[] = [];

    sort: string[] = [];

    setSort(propertyName: string, direction: string) {
        this.sort.push(propertyName + (direction === 'desc' ? ',desc' : ''));
    }

    removeSort(propertyName: string) {
        this.sort = this.sort.filter(_ => _.indexOf(propertyName) === -1);
    }

    resetSort() {
        this.sort = [];
    }
}