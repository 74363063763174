import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnDestroy, OnInit,
    Output,
    ViewChild
} from '@angular/core';
import { CdkVirtualScrollViewport } from "@angular/cdk/scrolling";
import { Country } from "../../models/network.model";
import { Observable } from "rxjs";
import { NetworkService } from "../../services/network.service";
import { MnpCoverageService } from "../../services/mnp-coverage.service";

@Component({
    selector: 'app-countries-select',
    templateUrl: 'countries-select.component.html',
    styleUrls: ['countries-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CountriesSelectComponent implements OnDestroy, OnInit, AfterViewInit {

    @Input() source: 'coverage'|'number-intelligence' = 'coverage';
    @Input() selected: number[] = [];
    @Input() disabledAll = false;
    @Input() multiple: boolean = true;
    @Input() showTitle: boolean = true;
    @Output() onChange = new EventEmitter<number[]>;

    @ViewChild('destinationWrapper') destinationWrapper: ElementRef;
    rowsVisible = 8;
    rowsVisibleForFilters = 15;

    tab: 'all'|'selected' = 'all';

    @HostListener('window:resize')
    onResize() {
        if (!this.destinationWrapper) { return; }
        const height = this.destinationWrapper.nativeElement.clientHeight - 30;
        if (!height && height <= 0) { return; }
        const rows = Math.max(Math.floor(height / 30), 8);
        if (isNaN(rows)) {
            console.error('Destination list Invalid size: ', rows);
        } else {
            if (rows !== this.rowsVisible) {
                this.rowsVisible = rows;
                this.cdr.detectChanges();
            }
        }
    }

    @ViewChild('scrollViewportSelected') private cdkVirtualScrollViewportSelected: CdkVirtualScrollViewport;
    @ViewChild('scrollViewportAll') private cdkVirtualScrollViewportAll: CdkVirtualScrollViewport;
    onChangeTab(tab: 'all'|'selected') {
        this.tab = tab;
        setTimeout(() => {
            this.cdkVirtualScrollViewportSelected.checkViewportSize();
            this.cdkVirtualScrollViewportAll.checkViewportSize();
        }, 100);
    }

    models: CountrySelect[] = [];
    toggleAll: boolean = false;
    searchQuery: string = '';
    timeoutId = null;

    selectedCount = 0;
    maxSelectedCount = 100;

    loading: boolean = false;

    @ViewChild('searchInput', { static: false }) searchInput: ElementRef;

    constructor(
        private cdr: ChangeDetectorRef,
        private mnpCoverageService: MnpCoverageService,
        private networkService: NetworkService
    ) {
    }

    ngOnInit() {

    }

    ngAfterViewInit() {
        this.onResize();
        this.update();
    }

    getSelectedModels(): CountrySelect[] {
        return this.models.filter(_ => _.selected);
    }

    getModels(): CountrySelect[] {
        return this.models;
    }

    update() {
        this.loading = true;
        this.loadModels().subscribe(models => {
            this.selectedCount = 0;
            this.models = models.map(d => this.prepareModel(d));
            this.loading = false;
            this.cdr.detectChanges();
        });
    }

    private loadModels(): Observable<Country[]> {
        switch (this.source) {
            case "coverage":
                return this.mnpCoverageService.countries()
            case "number-intelligence":
                return this.networkService.allCountries()
        }
    }

    private prepareModel(model: Country): CountrySelect {
        return {
            model: model,
            selected: this.selected.includes(model.id),
            __filterTextResult: false,
            search: [model.name, model.isoAlpha2, model.isoAlpha3].join('').toLowerCase()
        }
    }

    reset() {
        if (this.disabledAll) {return;}
        this.selectedCount = 0;
        this.models.forEach(_ => _.selected = false);
        this.selected = [];
        this.onChange.emit(this.selected);
        this.toggleAll = false;
        if (this.searchInput) {
            this.searchInput.nativeElement.value = '';
        }
        this.searchQuery = '';
        this.cdr.detectChanges();
    }

    onClickToggleAll() {
        if (!this.multiple || this.disabledAll) {return;}
        this.toggleAll = !this.toggleAll;
        let hasQuery = typeof this.searchQuery !== 'undefined' && !!this.searchQuery;
        let selectedCount = this.models.filter(_ => _.selected).length;
        this.getModels().filter(c => {
            if (hasQuery) {
                return c.__filterTextResult;
            }
            return true;
        }).forEach(c => {
            if (this.toggleAll) {
                selectedCount++;
            } else {
                selectedCount--;
            }
            if (this.toggleAll && selectedCount > this.maxSelectedCount) {
                return;
            }
            c.selected = this.toggleAll;
        });
        this.selectedCount = this.models.filter(_ => _.selected).length;
        this.selected = this.getSelectedModels().map(_ => _.model.id);
        this.onChange.emit(this.selected);
        this.cdr.detectChanges();
    }

    toggle(model: CountrySelect) {
        if (this.disabledAll) {return;}
        const selected = !model.selected;
        if (!this.multiple && selected) {
            this.models.forEach(m => m.selected = false)
        }
        model.selected = selected;
        this.toggleAll = (this.selectedCount === this.maxSelectedCount) || this.selectedCount === this.models.length
        this.selected = this.getSelectedModels().map(_ => _.model.id);
        this.onChange.emit(this.selected);
        this.cdr.detectChanges();
    }

    onSearchQueryChange(event: Event) {
        const value = (event.target as HTMLInputElement).value;
        if (value !== this.searchQuery) {
            this.searchQuery = value;
            this.cdr.detectChanges();
        }
    }

    ngOnDestroy() {
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    getTooltip(data: CountrySelect) {
        const tooltipText = data.model.name;
        if (tooltipText.length < 50) return null;
        return tooltipText;
    }
}

export interface CountrySelect {
    model: Country;
    selected: boolean;
    __filterTextResult: boolean;
    search: string
}

