import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ApiResponse, Page } from "../models/utils";
import { MnpProduct, MnpProductAllRequestParams, MnpProductUpdate } from "../models/mnp-product.model";

@Injectable({
    providedIn: 'root'
})
export class MnpProductService {

    constructor( private http: HttpClient) {}

    all(params: MnpProductAllRequestParams): Observable<Page<MnpProduct>> {
        const query = {
            page: params.page,
            size: params.size,
            search: params.search ? params.search : '',
            sort: params.sort && params.sort.length ? params.sort : [],
        };
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/products`;
        return this.http.get<ApiResponse<Page<MnpProduct>>>(url, {withCredentials: true, params: query}).pipe(
            map(r => r.data)
        );
    }

    save(model: MnpProductUpdate): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/products`;
        if (model.id) {
            return this.http.put<void>(url, model, {withCredentials: true});
        }
        return this.http.post<void>(url, model, {withCredentials: true});
    }

    delete(ids: number[]): Observable<void> {
        const url = `%BASE_URL%/number-intelligence/%ROLE%/suppliers-products/products?ids=${ids.join(',')}`;
        return this.http.delete<void>(url, {withCredentials: true});
    }

    create(): MnpProductUpdate {
        return {
            id: null,
            name: '',
            enabled: true,
            supplierId: null,
            config: {
                protocol: 'HTTP',
                responseType: 'application/json',
                mcc: '',
                mnc: '',
                url: '',
                method: 'GET',
                requestStatus: null,
                timeout: null
            }
        };
    }

}
