import { Component, Output, Input, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { DropdownCtx } from "../../shared/directives/dropdown.directive";
import { CdkDragDrop, moveItemInArray } from "@angular/cdk/drag-drop";
import { LocalStorage } from "../../shared/services/local-storage.service";
import { IFilterDef, loadFilters, saveFilters, sortFilters } from "../../shared/filter/filter";
import { NetworksSelectComponent } from "../../shared/components/networks-select/networks-select.component";
import { CoverageSearch } from "../../shared/models/coverage.model";
import {
    ProductSelect,
    ProductsSelectComponent
} from "../../shared/components/products-select/products-select.component";

@Component({
    selector: 'app-coverage-filter',
    templateUrl: 'coverage-filter.component.html',
    styleUrls: ['../../shared/filter/filter.scss'],
})

export class CoverageFilterComponent {

    @Input() model: CoverageSearch = {
        networksIds: [],
        productsIds: [],
    };

    @Output() onSubmit: EventEmitter<CoverageSearch> = new EventEmitter();

    loading = false;

    filters: IFilterDef[] = [
        { title: 'Networks', show: true, order: 1 },
        { title: 'Routes', show: true, order: 2 },
    ];
    private readonly filterStorageKey = 'coverage-filter';

    @ViewChild(NetworksSelectComponent, { static: false }) networksSelectComponent: NetworksSelectComponent;
    @ViewChild(ProductsSelectComponent, { static: false }) productsSelectComponent: ProductsSelectComponent;

    constructor(private storage: LocalStorage) {
        loadFilters(storage, this.filterStorageKey, this.filters);
        this.filters = sortFilters(this.filters);
    }

    resetFilters() {
        let needUpdate = false;
        this.filters.forEach(f => {
            if (f.value) {
                this.onChangeFilterValue('', f, false);
                needUpdate = true;
            }
        });
        if (this.networksSelectComponent) {
            this.networksSelectComponent.reset();
        }
        if (this.productsSelectComponent) {
            this.productsSelectComponent.reset();
        }
        if (needUpdate) {
            this.onSubmitClick();
        }
    }

    toggleFilter(filter: IFilterDef) {
        filter.show = !filter.show;
        if (filter.value && !filter.show) {
            this.onChangeFilterValue('', filter);
        }
    }

    getHiddenFilters() {
        return this.filters.filter(_ => !_.show);
    }

    getShowFilters() {
        return this.filters.filter(_ => _.show);
    }

    onClickShowFilter(filter: IFilterDef, dropdown: DropdownCtx) {
        filter.show = true;
        if (!this.getHiddenFilters().length) {
            dropdown.hide();
        }
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onSubmitClick() {
        this.onSubmit.emit(this.model);
    }

    onChangeFilterValue(value: any, filter: IFilterDef, submit = true) {
        switch (filter.title) {
            case 'Networks':
                this.model.networksIds = value ? value : [];
                filter.value = value.length ? String(value.length) : '';
                break;
            case 'Routes':
                const routes = value as ProductSelect[];
                this.model.productsIds = routes ? routes.map(_ => _.product.id) : [];
                filter.value = routes.length ? String(routes.length) : '';
                break;
        }
        if (submit) {
            this.onSubmitClick();
        }
    }

    onDropFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        const filter = event.item.data;
        if (!filter.show) {return;}
        this.toggleFilter(filter);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onDropPosFilter(event: CdkDragDrop<any, any, IFilterDef>) {
        moveItemInArray<IFilterDef>(this.filters, event.previousIndex, event.currentIndex)
        this.filters.forEach((f, i) => f.order = i + 1);
        this.filters = sortFilters(this.filters);
        saveFilters(this.storage, this.filterStorageKey, this.filters);
    }

    onShowFilter(filter: IFilterDef, dropdownContent: TemplateRef<any>) {
        const el = dropdownContent.elementRef.nativeElement.parentNode as HTMLElement;
        setTimeout(() => {
            const input = el.querySelector('.dropdown-menu .form-control') as HTMLElement;
            if (input) { input.focus();}
        });
    }
}